import React, {useEffect, useState} from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import TermsAndConditions from '../comps/TermsAndConditions';
import { loginFetch, registerFetch } from '../../scripts/userFunctions';

//validate mail function
import { validateEmail } from '../../scripts/validateMail'

export default function LoginPage(props) {
    const nav = useNavigate()
    const [register, setRegister] = useState(0);
    const [displayTerm, setDisplayTerm] = useState(0)
    const [formData, setformData] = useState({fname:'', fpassword:'', fActivationCode:'', fPasswordConfirmation:'', fPassword:'',fFullname:'',fPhone:'', fGmail:''});
    const {setLoginInfo} =  useOutletContext()

    useEffect(()=>{
        const passwordConfirmation = document.getElementById('fPasswordConfirmation')
        if (passwordConfirmation){
            if (formData.fPassword !== formData.fPasswordConfirmation){
                passwordConfirmation.style.color = 'red'
            }
            else{
                passwordConfirmation.style.color = 'black'
            }
        }
    }, [formData.fPasswordConfirmation, formData.fPassword])

    //Change window
    function openRegisterWindow(){
        setRegister(prev=>{return !prev})
    }

    ////Form functions
    function handleChange(e){
        setformData(prevformData=>{
          return {
            ...prevformData,
            [e.target.id]: e.target.value
          }
        });
      }

    //
    async function submitLogin(e){
        e.preventDefault()

        //get required data 
        const {fname, fpassword} = formData
        const res = await loginFetch({
            data:fname.trim(),
            passcode:fpassword.trim()
        })
        if (res){
            setLoginInfo(res)
            nav('/home')
        }
        else{
            //change color
        }
    }

    //
    async function submitRegister(e){
        e.preventDefault()
        ////check stuff
        //check email
        if (!validateEmail(formData.fGmail)){
            alert('البريد الإلكتروني غير صالح')
            return false
        }

        //phone
        const fphone = formData.fPhone
        if (!fphone.startsWith(0) && fphone.length !== 11){
            formData.fPhone = '0'+formData.fPhone
        }

        //password & passwordConfiermation
        if(formData.fPassword !== formData.fPasswordConfirmation){
            alert('كلمة المرور غير متطابقة')
            return 0
        }

        //get required data 
        const res = await registerFetch({
            fullName:formData.fFullname.trim(),
            //birthday:formData.fBirthday,
            //residence:formData.fResidence.trim(),
            //job:formData.fJob.trim(),
            phone:formData.fPhone.trim(),
            gmail:formData.fGmail.trim(),
            //username:formData.fUsername.trim(),
            passcode:formData.fPassword.trim(),
            activationCode:formData.fActivationCode,
        })
        if (res){
            setLoginInfo({res})
            nav('/home')
        }

    }


    if (register){
        return ( 
            <form className='root-basic login-page' onSubmit={submitRegister}>

                <h1 className='header-1 break-2'>المعلومات الشخصية:</h1>
                <fieldset className='break-4' id='personalInfo'>                        
                    <div>
                        <h5>الاسم الكامل</h5>
                        <input  type='text' id="fFullname" value={formData.fFullname} onChange={handleChange} dir='auto' required/>
                    </div>

                    <div>
                        <h5>رقم الهاتف</h5>
                        <input type='tel' id="fPhone" value={formData.fPhone} onChange={handleChange} dir='auto' required maxLength="11" minLength="10"/>
                    </div>

                    <div>
                        <h5>عنوان البريد الالكتروني</h5>
                        <input type='text' id="fGmail" value={formData.fGmail} onChange={handleChange} dir='auto' required/>
                    </div>                    
                </fieldset>

                <h1 className='header-1 break-2'>كلمة المرور:</h1>
                <fieldset className='break-4' id='activationCodeInfo'>
                    <div>
                        <h5>كلمة المرور</h5>
                        <input type='password' id="fPassword" value={formData.fPassword} onChange={handleChange} dir='auto' required/>
                    </div>

                    <div>
                        <h5>التحقق من كلمة المرور</h5>
                        <input type='password' id="fPasswordConfirmation" value={formData.fPasswordConfirmation} onChange={handleChange} dir='auto' required/>
                    </div>
                    </fieldset>

                {!!displayTerm && 
                <TermsAndConditions id='term&Conds'/>
                }   

                <p className='break-4 par' dir='rtl'>
                    <input type='checkbox' required/>
                    يرجی الموفقة علی 
                    <button className='btn-3' type='button' onClick={()=>{setDisplayTerm(prev=>{return !prev})}}> شروط الاستخدام والاحكام </button>
                    قبل تفعيل الحساب علی الموقع
                    
                </p>        

                <fieldset className='center' id='activationSection'>
                    <h3 className='colored break-05'>رمز التفعيل</h3>
                    <input  type='text' id="fActivationCode" value={formData.fActivationCode && formData.fActivationCode.replaceAll(' ', '').match(/.{1,5}/g).join(' ')} onChange={handleChange} required maxLength="29" minLength="29"/>
                    <button className='btn-pr' type='submit'>انشاء حساب</button>
                </fieldset>                 

                
            </form>
        )
    }

    return (
        <div className='root-basic login-page'>
            <div className='header'>
                <h1 className='header-1 break-05'>مرحبا بكم في</h1>
                <h1 className='header-1'>Creators Zone</h1>   
            </div>  

            <form className='break-4' onSubmit={submitLogin}>
                <fieldset className='break-1 login-form'>
                    
                        <h5>رقم الهاتف</h5>
                        <input type='text' id="fname" value={formData.fname} onChange={handleChange} required/>
                    
                        <h5>كلمة المرور</h5>
                        <input type='password' id="fpassword" value={formData.fpassword} onChange={handleChange} required/>
                    
                    <br></br>
                    <button className='btn-pr' type='submit'>تسجيل الدخول</button>
                </fieldset>    

                
            </form> 

            <p className='break-05'>اذا لم يكن لديك حساب يرجی</p>
            <button className='btn-3' onClick={openRegisterWindow}>انشاء حساب جديد</button>
        </div>
    )
}