import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function TermsAndConditions(props) {
  const nav = useNavigate()
  return (
    <div className='root-basic terms-and-conductions'>
      <h1 className='header-1 break-1'>شروط الاستخدام والاحكام</h1>
      <p className='mix-lang' lang="ar" dir='ltr'>
      يرجى قراءة هذه الشروط والأحكام بعناية قبل تفعيل الحساب على موقع <span dir="rtl">Creators Zone </span>الذي يتم تشغيله بواسطة شركة<span dir="rtl"> KKC</span>.<em>ا</em>
      </p>

      <h5>شروط الاستخدام:<em>ا</em></h5>
      <p>
                    عند تفعيلك للحساب، فإنك تقر بأنك قد قرأت وراجعت هذه الاتفاقية
              وأنك توافق على الالتزام بشروطها. إذا كنت لد تريد الدلتزام بشروط هذه
        الاتفاقية، موقع <span dir="rtl">Creators Zone </span> يسمح بالاستخدام والوصول إلى هذا
                        الموقع ومنتجاته لأولئك الذين قد وافقوا على شروط الاستخدام.<em>ا</em>
      </p>

      <h5>السياسة الخصوصية:<em>ا</em></h5>
      <p>
              جميع معلوماتك الشخصية المستخدمة في الموقع محمية ولن يتم 
        استخدامها إلد لتعزيز تجربتك وإعلامك بالفرص ذات الصلة. كما نطلب
        منك الامتناع عن مشاركة الموقع أو رموز التفعيل مع الأفراد الذين لم
        يقوموا بشرائها. يساعد هذا الإجراء في الحفاظ على سلامة خدماتنا
        ويضمن تجربة عادلة لجميع المستخدمين. وبخلاف ذلك قد يتم إيقاف
        الحساب بشكل نهائي.<em>ا</em>
      </p>

      <h5>الملكية الفكرية:<em>ا</em></h5>
      <p>
      أنت توافق على أن جميع المواد والمنتجات والخدمات المقدمة على هذا
        الموقع هي ملك موقع <span dir="rtl">Creators Zone </span> و الشركة<span dir="rtl"> KKC</span>  . بما في ذلك
        جميع حقوق الطبع والنشر وأنت توافق أيضا عل أنك لن تقوم بإعادة
        إنتاج أو إعادة توزيع الملكية الفكرية بأي شكل من الأشكال، بما في ذلك
        تسجيلات العلامات التجارية الإلكترونية أو الرقمية أو الجديدة.<em>ا</em>
      </p>

      <h5>حدود المسؤولية:<em>ا</em></h5>
      <p>
      موقع <span dir="rtl">Creators Zone </span> ليس مسؤول عن أي أضرار قد تحدث لك نتيجة
        سوء استخدامك لموقعنا،
        يحتفظ موقع <span dir="rtl">Creators Zone </span>  بالحق في تعديل هذه الاتفاقية وتعديلها
        وتغييرها في أي  وسوف نعلم مستخدمينا بهذه التغييرات عبر
        البريد الإلكتروني.<em>ا</em>
      </p>

      <p className='center-text'>
      هذه الاتفاقية عبارة عن تفاهم بين <span dir="rtl" className='blue-text'>Creators Zone </span> و <span className='blue-text'>المستخدم</span>، وهذا
      يحل محل جميع الاتفاقيات السابقة المتعلقة باستخدام هذا الموقع.<em>ا</em>
      </p>

      {props.page &&
        <button className='btn-pr' onClick={()=>{nav('/home')}}>العودة إلى الصفحة الرئيسية</button>
      }
      
    </div>
  )
}
