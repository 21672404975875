let domain = 'https://api.creatorszone.net'
 
function statusAlert(res, data){
  if (res.startsWith('2'))return data;

  else if (res.startsWith('4')){
    alert(data)
  }
  else if (res.startsWith('5')){
    console.log('500 Server issue, please try again later')
  }
  return false
}
 
const loginFetch = async(body)=>{
  try{
    const res = await fetch(domain+"/api/user/login", {
    method:'POST',
    mode:'cors',
    credentials: "include",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'BrowserToken': localStorage.getItem('browserToken')
        //http://localhost:4000
      },
      body: JSON.stringify(body)
  })
  ///get json response///
  return statusAlert(res.status+'', await res.json())}

  catch(err){
    alert(err)
    return false}
}

const logoutFetch = async ()=>{
  try{
    const serverResponse = await fetch(domain+"/api/users/logout", {
    method:'GET',
    mode:'cors',
    credentials: "include",
    headers: {
        'Accept': 'application/json',
      },
  })
  ///get json response///
  if (serverResponse.ok){ 
    return true
  }
  return false
  }

  catch(err){
    alert(err)
    return false}
}

const autoLogin = async()=>{
  try{
    const res = await fetch(domain+"/api/user/autoLogin", {
    method:'GET',
    mode:'cors',
    credentials: "include",
  })
  ///get json response///
  if (res.ok)return await res.json();
  return false
}

  catch(err){
    alert(err)
    return false}
}

const registerFetch = async(body)=>{
  try{
    const res = await fetch(domain+"/api/user/register", {
    method:'POST',
    mode:'cors',
    credentials: "include",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'BrowserToken': localStorage.getItem('browserToken')
      },
      body: JSON.stringify(body)
  })
  ///get json response///
  return statusAlert(res.status+'', await res.text())}

  catch(err){
    alert(err)
    return false}
}
 
const fetchPage = async(pageNumber, path)=>{
  try{
    const res = await fetch(domain+"/api/book/"+path, {
    method:'GET',
    mode:'cors',
    credentials: "include",
    headers: {
        'pageNumber': pageNumber,
      },
  })
  ///get json response///
  if (res.ok) return await res.blob();
  return 0;
}
  //return statusAlert(res.status+'', await res.json())}

  catch(err){
    console.log(err)
    return false}
}

const postQuestionFetch = async(body)=>{
  try{
    const res = await fetch(domain+"/api/user/question", {
    method:'POST',
    mode:'cors',
    credentials: "include",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      //'BrowserToken': localStorage.getItem('browserToken')
    },
    body: JSON.stringify(body)
  })
  ///get json response///
  if (res.ok) return true;
  else return await res.json();
}
  //return statusAlert(res.status+'', await res.json())}

  catch(err){
    alert(err)
    return false}
}

const examFetch = async(examNum)=>{
  try{
    const res = await fetch(domain+"/api/book/exam", {
    method:'GET',
    mode:'cors',
    credentials: "include",
    headers: {
        'exam': examNum,
      },
  })
  ///get json response///
  if (res.ok) return await res.json();
  return 0;
}
  //return statusAlert(res.status+'', await res.json())}

  catch(err){
    console.log(err)
    return false
  }
}

const saveExamFetch = async(body)=>{
  try{
    const res = await fetch(domain+"/api/book/examResult", {
    method:'PUT',
    mode:'cors',
    credentials: "include",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      //'BrowserToken': localStorage.getItem('browserToken')
    },
    body: JSON.stringify(body)
  })
  ///get json response///
  if (res.ok) return true;
  return 0;
}
  //return statusAlert(res.status+'', await res.json())}

  catch(err){
    console.log(err)
    return false
  }
}
 
const fileFetchtest = async()=>{
  // Step 1: start the fetch and obtain a reader
  let response = await fetch(domain+"/api/user/extraContent", {
    method:'GET',
    mode:'cors',
    credentials: "include",
  })

  const reader = response.body.getReader();

  // Step 2: get total length
  const contentLength = +response.headers.get('Content-Length');

  // Step 3: read the data
  let receivedLength = 0; // received that many bytes at the moment
  let chunks = []; // array of received binary chunks (comprises the body)

  while(true) {
    const {done, value} = await reader.read();

    if (done) {
      break;
    }

    chunks.push(value);
    receivedLength += value.length;

    console.log(`Received ${receivedLength} of ${contentLength}`)
  }

  // Step 4: concatenate chunks into single Uint8Array
  let chunksAll = new Uint8Array(receivedLength); // (4.1)
  let position = 0;
  for(let chunk of chunks) {
    chunksAll.set(chunk, position); // (4.2)
    position += chunk.length;
  }


}

const fileFetch = async()=>{
  try{
    const res = await fetch(domain+"/api/user/extraContent", {
    method:'GET',
    mode:'cors',
    credentials: "include",
  })
  ///get json response///
  if (res.ok) return await res.blob();
  return 0;
}
  //return statusAlert(res.status+'', await res.json())}

  catch(err){
    console.log(err)
    return false}
} 

export {loginFetch, registerFetch, autoLogin, fetchPage, logoutFetch, postQuestionFetch, examFetch, saveExamFetch, fileFetch}