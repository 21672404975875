import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { postQuestionFetch } from '../../scripts/userFunctions'

export default function FAQPage() {
    const {loginInfo} = useOutletContext()
    const [formQuestion, setFormQuestion] = useState('')
    const [questionRes, setQuestionRes] = useState()
    const nav = useNavigate()

    useEffect(()=>{
        sessionStorage.setItem('path', 'UserQuestions')
    }, [])

    async function submitQuestion(e){
      const sendBtn = document.getElementById('sendBtn')
      e.preventDefault()
      if (formQuestion){
        const res = await postQuestionFetch({question:formQuestion})
        if (res === true){
          setQuestionRes(' تم ارسال السوال بنجاح');
        }
        else{
          setQuestionRes(res);
        }
        sendBtn.disabled = true
      }
    }

  return (
    <div className='root-basic FAQ-page'>
      <h1 className='break-1' dir='rtl'>اسال creators Zone</h1>
      <form>
        <label htmlFor="fQuestion" dir='rtl'>من {loginInfo.fullName}</label>
        {questionRes ? 
        <h1 dir='rtl'>{questionRes}</h1>
        : 
        <textarea name="paragraph_text" cols="50" rows="10" id="fQuestion" dir='auto' maxLength='255' value={formQuestion} onChange={(e)=>{setFormQuestion(e.target.value)}}></textarea>
        }
        <div>
          <button type="button" className='btn-sc' onClick={()=>{nav('/home')}}>إلغاء</button>
          <button type="submit" className='btn-pr' onClick={submitQuestion} id='sendBtn'>إرسال</button>
        </div>
      </form>
    </div>
  )
}
