import React, {useEffect, useRef, useState} from 'react'

import { Outlet, useNavigate } from 'react-router-dom'
import { autoLogin } from '../../scripts/userFunctions'

export default function Base() {
  const nav = useRef(useNavigate())
  const [loginInfo, setLoginInfo] = useState(null)

  useEffect(()=>{ 
    autoLogin().then(res=>{
      if (res){
        setLoginInfo(res)

        //return the last page the user was on
        const path = sessionStorage.getItem('path')
        if (path)return nav.current("./"+path);
        nav.current('./home')
      }
    })
  }, [])
   


  return (
    <div className='base'>
        <div className='background-wallpaper'></div>
        <Outlet context={{
                  loginInfo:loginInfo,
                  setLoginInfo:setLoginInfo,
                }}>
        </Outlet>
    </div>
  )
}
