import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

export default function ProfilePage() {
    const nav = useNavigate() 
    const {loginInfo} = useOutletContext()

  return (
    <div className='root-basic'>
    <h1 className='header-1 break-4 header-r'>:معلومات الحساب الشخصية</h1>

      <div className='user-profile-data'>
        <h2>الاسم الاول</h2>
        <input readOnly value={loginInfo.fullName}/>

        <h2>رقم الهاتف</h2>
        <input readOnly value={loginInfo.phone}/>

        <h2>عنوان البريد الالكتروني</h2>
        <input readOnly value={loginInfo.gmail}/>

        <h2>تاريخ التسجيل</h2>
        <input readOnly value={loginInfo.createdAt.slice(0, 10)}/>

        <button className='btn-pr' onClick={()=>{nav('./home')}}>العودة الی الصفحة الرئيسية</button>
      </div>
    </div>
  )
}
