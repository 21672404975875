import React, { useEffect, useRef, useState } from 'react'
import { fetchPage, examFetch, saveExamFetch } from '../../scripts/userFunctions'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

//icons
import icon_bw from '../../assets/icons/Black and white icon.PNG'
import { ReactComponent as TrinagleSvg } from '../../assets/icons/triangle-icon.svg'
import ArrowSvg from '../../assets/icons/arrow-icon.svg'


export default function Book() {
  const [pageNumber, setPageNumber] = useState(0)
  const [pageDropdown, setPageDropdown]  = useState(false)
  const [isloading, setIsloading] = useState(false)  
  const loadedPages = useRef({});
  const nav = useRef(useNavigate());
  const state = useLocation().state;
  const examAnswers = useRef();
  const examQuestions = useRef();
  const outletContext = useOutletContext();
  const invertArrowList = useRef([1, 6, 15, 26, 28, 36, 67, 92, 96, 100, 114])
  const pageMode = useRef('page')
  const maxPage = useRef(114)

  //fetch inital page
  useEffect(()=>{
    //set the path for auto restart
    sessionStorage.setItem('path', 'book')

    //get exam
    if (state){
      if (state.exam){
        examFetch(state.exam).then(res=>{
          //update examAnswers ref hook and render the exam inplace of the page
          examAnswers.current = res.answers
          delete res.answers
          examQuestions.current = res
          setPageNumber('exam ' + state.exam)
          preloadPages(99)
        })
      }

      if (state.manual){
        pageMode.current = ('page-manual')
        invertArrowList.current = [1, 3]
        maxPage.current = 11
      }
    } 

    //fetch page
    let num = 1;
    if (parseInt(localStorage.getItem(pageMode.current))) num = parseInt(localStorage.getItem(pageMode.current));
    fetchPage(num, pageMode.current).then((res)=>{
      if (res){
        //document.getElementById('pageContainer').innerHTML = res //+ invsButtons.current
        loadedPages.current[num] = URL.createObjectURL(res)
        preloadPages(num)
        setPageNumber(num)
      }

      else{
        alert('غير قادر على تحميل الصفحة ، يرجى إعادة تشغيل الموقع')
      }
    }) 
  

  }, []) 

  //loading filter for pages & ddos
  function isloadingFilter(on){
    //turn on the loading filter
    if (on){
      const pageTag = document.getElementById('pageContainer')
      pageTag.style.filter = 'blur(5px)'
      pageTag.style.cursor = 'wait'
      setIsloading(true)
    }
    //turn off the loading filter
    else{
      const pageTag = document.getElementById('pageContainer')
      pageTag.style.filter = 'blur(0px)'
      pageTag.style.cursor = 'auto'
      setIsloading(false)
    }
  }  

  //fetch page from server & update website
  async function fetchOnePageFun(num){
    //check for exam location
    if (num === 100 && !examAnswers.current){
      examFetch(1).then(res=>{
        //update examAnswers ref hook and render the exam inplace of the page
        examAnswers.current = res.answers
        delete res.answers
        examQuestions.current = res
        setPageNumber('exam ' + 1)
        preloadPages(100);
      })
    }
    else{
      examAnswers.current = null
      examQuestions.current = null
      if (num > 0 && num <= maxPage.current){
        if (num !== pageNumber && !isloading){
          isloadingFilter(true)
  
          //get already loaded page
          if (loadedPages.current[num] && loadedPages.current[num]!=='loading'){
            
          }
          //load new page and display it
          else{
            const res = await fetchPage(num, pageMode.current)
            if (res){
              loadedPages.current[num] = URL.createObjectURL(res)
              }
            }
  
          //save the page, update state, scroll to top & remove loading filter
          localStorage.setItem(pageMode.current, num)
          setPageNumber(num)
          window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
          isloadingFilter(false)
          preloadPages(num)
        }
      }
    }
  }

  //fetch next page in advanced
  async function preloadPages(num){
    //if next page is empty, preload 3 in advance
    if (!loadedPages.current[num+2]){
      for (let i=1; i<5; i++){
        if (num+i <= maxPage.current && !loadedPages.current[num+i]){
          loadedPages.current[num+i] = 'loading'
          const res = await fetchPage(num+i, pageMode.current)
          if (res){
            loadedPages.current[num+i] = URL.createObjectURL(res)
            console.log('page ' + (num+i) + ' loaded')
            }
        }
      }
    }

  } 

  function loadExam(questions){
    //set the exsisting 
    if (outletContext.loginInfo.tests){
      const userAnswers = JSON.parse(outletContext.loginInfo.tests).result
      const trueAnswers = examAnswers.current
      return Object.keys(questions).map((key, i) =>(
        <fieldset key={i} id={key}>
          <p>{questions[key].q}؟</p>
          <span>
            <input type="radio" id={key+'-a'} name={key} value="a" required disabled/>
            {trueAnswers[i] === 'a' ? 
            <label htmlFor={key+'-a'}  style={{color:'green'}}> {'أ) ' + questions[key].a}</label>:
            <>
            {userAnswers[i] === 'a' ?
            <label htmlFor={key+'-a'} style={{color:'red'}}> {'أ) ' + questions[key].a}</label>:
            <label htmlFor={key+'-a'}> {'أ) ' + questions[key].a}</label>}
            </>}
          </span>
  
          <span>
            <input type="radio" id={key+'-a'} name={key} value="a" required disabled/>
            {(trueAnswers[i] === 'b') ? 
            <label htmlFor={key+'-b'} style={{color:'green'}}> {'ب) ' + questions[key].b}</label>:
             <>
             {userAnswers[i] === 'b' ? 
             <label htmlFor={key+'-b'} style={{color:'red'}}> {'ب) ' + questions[key].b}</label>:
             <label htmlFor={key+'-b'} > {'ب) ' + questions[key].b}</label>}
             </>}
          </span>
  
          <span>
            <input type="radio" id={key+'-a'} name={key} value="a" required disabled/>
            {trueAnswers[i] === 'c' ? 
            <label htmlFor={key+'-c'}  style={{color:'green'}}> {'ج) ' + questions[key].c}</label>:
            <>
            {userAnswers[i] === 'c' ?
            <label htmlFor={key+'-c'} style={{color:'red'}}> {'ج) ' + questions[key].c}</label>:
            <label htmlFor={key+'-c'}> {'ج) ' + questions[key].c}</label>}
            </>}
          </span>
  
          <span>
            <input type="radio" id={key+'-a'} name={key} value="a" required disabled/>
            {trueAnswers[i] === 'd' ? 
            <label htmlFor={key+'-d'}  style={{color:'green'}}> {'د) ' + questions[key].d}</label>:
            <>
            {userAnswers[i] === 'd'?
            <label htmlFor={key+'-d'} style={{color:'red'}}> {'د) ' + questions[key].d}</label>:
            <label htmlFor={key+'-d'}> {'د) ' + questions[key].d}</label>}
            </>}
          </span> 
        </fieldset>
      ))
    }
    return Object.keys(questions).map((key, i) =>(
      <fieldset key={i} id={key}>
        <p>{questions[key].q}؟</p>
        <span>
          <input type="radio" id={key+'-a'} name={key} value="a" required/>
          <label htmlFor={key+'-a'}> {'أ) ' + questions[key].a}</label>
        </span>

        <span>
          <input type="radio" id={key+'-b'} name={key} value="b"/>
          <label htmlFor={key+'-b'}> {'ب) ' + questions[key].b}</label>
        </span>

        <span>
          <input type="radio" id={key+'-c'} name={key} value="c"/>
          <label htmlFor={key+'-c'}> {'ج) ' + questions[key].c}</label>
        </span>

        <span>
          <input type="radio" id={key+'-d'} name={key} value="d"/>
          <label htmlFor={key+'-d'}> {'د) ' + questions[key].d}</label>
        </span> 
      </fieldset>
    ))
  }

  function validateExam(e){
    e.preventDefault()
    const abcMap = {a:1, b:2, c:3, d:4}
    //highlight the correct answers
    const userAnswers = []
    Object.keys(examQuestions.current).forEach((key, i) =>{
      e.target.children[i].disabled = true

      const userAnswer = e.target[key].value
      userAnswers.push(userAnswer)

      if(userAnswer === examAnswers.current[i]){
        e.target.children[i].children[abcMap[userAnswer]].style.color = 'green'
      }
      else{
        e.target.children[i].children[abcMap[userAnswer]].style.color = 'red'
        e.target.children[i].children[abcMap[examAnswers.current[i]]].style.color = 'green'
      } 
    })
    saveExamFetch({result:userAnswers, exam:pageNumber}).then(res=>{
      if (res){
        //scroll to top & update btn
        window.scrollTo({
          top: 0,
          behavior: 'smooth'  
        });
        outletContext.setLoginInfo(prev=>{return {...prev, tests:JSON.stringify({result:userAnswers, exam:pageNumber})}})
      }
    })
  } 

  return (
    <div className='root-basic book-page'> 
      <nav id='navbar'>
        <button type="button" className='btn-sc' onClick={()=>{nav.current('/home')}}><img src={icon_bw} alt='logo'/><p> Creators Zone</p></button>
        <button type="button" className='btn-3 dropdown-foucs' onClick={()=>{setPageDropdown(prev=>{return !prev})}}>
            <TrinagleSvg className='svg'/>
            <label>صفحة {pageNumber ? pageNumber : 1}</label>
          {(pageDropdown && !isloading) && 
              <div className='page-dropdown' onClick={()=>{}}>
              {[...Array(maxPage.current+2).keys()].slice(1, -1).map(i=>{
                return <span key={i} name={i} onClick={()=>{fetchOnePageFun(i)}}>صفحة {i}</span>
              })}
            </div>
            }
        </button>
      </nav>
          
      <div>
        <div id='pageContainer'>
          {!examQuestions.current &&
          <>
            {loadedPages.current[pageNumber] ?
              <img src={loadedPages.current[pageNumber]} alt=""/>
            :
            <div className='page-placeholder'>
              <div className="loader"></div>
            </div>
            }
          </>
          }
        </div>

        {examQuestions.current &&
          <form id='examForm' onSubmit={validateExam}>
            {loadExam(examQuestions.current)}

            {outletContext.loginInfo.tests ?
            <div className='btns'>
            <button className='btn-sc' type="button" onClick={()=>{fetchOnePageFun(99)}}>رجوع</button>
            <button className='btn-pr' type="button" onClick={()=>{fetchOnePageFun(100)}}>تالي</button>
            </div>:
            <button className='btn-pr' type="submit" id='submitBtn'>Submit</button>}
          </form>
        }
        
      </div>
      <footer>
        {!examQuestions.current &&
          <div id="nextButtons">
            {!invertArrowList.current.includes(pageNumber) ? 
            <>
              <img src={ArrowSvg} type='button' className='btn-left' onClick={()=>{ fetchOnePageFun(pageNumber-1)}}/>
              <img src={ArrowSvg} type='button' className='btn-right' onClick={()=>{ fetchOnePageFun(pageNumber+1)}}/>
            </>
              :
            <>
              {pageNumber!==1 ? <img src={ArrowSvg} type='button' className='btn-left white' onClick={()=>{ fetchOnePageFun(pageNumber-1)}}/> : <div></div>}
              {pageNumber!==114 && <img src={ArrowSvg} type='button' className='btn-right white' onClick={()=>{ fetchOnePageFun(pageNumber+1)}}/>}
            </>
              }
        </div>
        }
      </footer>
    </div>
  )
}