import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Tutorial(props) {
  const nav = useNavigate()
  return (
    <div className='root-basic terms-and-conductions'>
      <h1 className='header-1 break-1'>إرشادات القراءة</h1>

      <h5>تحديد الأهداف بوضوح:<em>ا</em></h5>
      <p>حدد أهدافك بوضوح قبل قراءة هذا الكتاب من خلال معرفة الغاية الاساسية من تعلم مهارة صناعة المحتوى وما الذي تريد تحقيقه عند الانتهاء من قراءة الكتاب، تأكد أن تكون واقعيًا وذكيًا في وضعها.<em>ا</em>
      </p>

      <h5>قراءة بكفاءة عالية:<em>ا</em></h5>
      <p>
      أثناء القراءة تأكد من كتابة الملاحظات وطرح الأسئلة وتجربة النصائح المطروحة و تدوين الأفكار. وفكر بتطبيق ما طرح في الكتاب بأسلوبك الخاص وما يتكيف مع وضعك الحالي.
        <em>ا</em>
      </p>

      <h5>اتبع التسلسل:
        <em>ا</em></h5>
      <p>
      تمت هيكلة الكتاب لبناء المفاهيم بشكل تدريجي حسب احتياجك لها، اتبع التسلسل لضمان تجربة قراءة أفضل.
        <em>ا</em>
      </p>

      <h5> المراجعة بانتظام:
        <em>ا</em></h5>
      <p>
      مجال صناعة المحتوى مجال متغير باستمرار، من الأفضل المراجعة بين فترة وأخرى لآخر المستجدات والتطورات في المجال وعدم الاعتماد على مصدر فردي في رحلة التعلم.
        <em>ا</em>
      </p>

      <h5> استغل النقد البناء:
        <em>ا</em></h5>
      <p>
      أثناء تطبيق الخطة ومشاركة المحتوى، لا تنسى الأخذ من التعليقات البناءة وآراء الزملاء لتطوير المحتوى وتصحيح الأخطاء.
        <em>ا</em>
      </p>

      <p className='center-text'>
        أخيرًا وليس أخرا احتفل بنجاحاتك فقرارك بدخول هذا المجال ليس قرارًا سهلًا أبدًا وربما سيكون رحلة طويلة مليئة بالعقبات وكذلك التجارب التي ستغير من شخصيتك وحياتك المهنية في المستقبل.
        <em>ا</em>
      </p>
      <button className='btn-pr' onClick={()=>{nav('/home')}}>العودة إلى الصفحة الرئيسية</button>

      
    </div>
  )
}
