import React, { useEffect, useState } from 'react'
//import { useOutletContext } from 'react-router-dom'

/*
        <button className='btn-sc break-1'                onClick={()=>{nav('/Terms&conditions')}}>شروط الاستخدام والاحكام</button>
        <button className='btn-sc break-1'                onClick={()=>{nav('/ExtraContent')}}>محتوى إضافي</button>
*/


import icon_bw from '../../assets/icons/Black and white icon.PNG'
import { useNavigate } from 'react-router-dom'
//onClick={()=>{nav('/book', {state:{exam:1}})}}
export default function HomePage() {
  const nav = useNavigate()
  const [loaded, setLoaded] = useState(false)
  useEffect(()=>{
    sessionStorage.setItem('path', 'home')
  }, [])


  return (
    <div className='root-center'>
      <h1 className='header-1 break-4 '>Creators Zone</h1>

      <div className='pr-btns break-1'>
        <button className='btn-sc break-1 spaced-content' onClick={()=>{nav('/book')}}><img src={icon_bw} alt='"logo"'/><p>الكتاب الالكتروني</p></button>
        <button className='btn-sc break-1'                onClick={()=>{nav('/book', {state:{manual:true}})}}>دليل وصول الفيديوهاتة المليونية</button>

        {loaded ? 
        <a className='btn-sc break-1'>تحميل المكافئة إضافية</a>  
        :
        <a className='btn-sc break-1' href='https://api.creatorszone.net/api/user/extracontent' download='مكافئة إضافية.zip' target='_self' onClick={()=>{setTimeout(()=>{setLoaded(true)}, 500)}}>مكافئة إضافية</a>  
        }

        <button className='btn-sc break-1'                onClick={()=>{nav('/tutorial')}}>ارشادات القراءة</button>
        <button className='btn-sc break-1'                onClick={()=>{nav('/profile')}}>معلومات الحساب الشخصي</button>
      
      </div>

      <div className='sc-btns break-2'>
        <button className='btn-sc break-05 spaced-content'><p>قريبا</p><p>المسابقة رقم ١</p></button>
        <button className='btn-sc spaced-content'><p>قريبا</p><p>المسابقةالاخيرة</p></button>
      </div>

      <footer className='footer'>
        <p dir='rtl' className='center'>
          اذا كان لديك اي سؤال يرجی التفضل بتقديمه 
          <button type="button" className='btn-3' onClick={()=>{nav('/UserQuestions')}}>من هنا</button>  
        </p>
      </footer>
    </div>
  )
}
