import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

//Comps
import Base from './components/layout/Base'
import HomePage from './components/pages/HomePage';
import ProfilePage from './components/pages/ProfilePage';
import BookPage from './components/pages/BookPage';
import TutorialPage from './components/pages/TutorialPage';
import LoginPage from './components/pages/LoginPage';
import PrivateRoute from './components/comps/PrivateRoute';
import FAQPage from './components/pages/FAQPage';
import IntroductionPage from './components/pages/IntroductionPage';

//import TermsAndContionsPage from './components/pages/Terms&ContionsPage';
//<Route path="Terms&conditions" element={<TermsAndContionsPage/>}/>
//import ExtraContent from './components/pages/ExtraContent';
//<Route path="ExtraContent" element={<PrivateRoute Component={ExtraContent}/>}/>
//constants

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Base/>} >
          <Route path="" element={<Navigate to="/login" replace/>}/>
          <Route path="login" element={<LoginPage/>}/>

          <Route path="home" element={<PrivateRoute Component={HomePage}/>}/>
          <Route path="profile" element={<PrivateRoute Component={ProfilePage}/>}/>
          <Route path="tutorial" element={<PrivateRoute Component={TutorialPage}/>}/>
          <Route path="book" element={<PrivateRoute Component={BookPage}/>}/>
          <Route path="UserQuestions" element={<PrivateRoute Component={FAQPage}/>}/>
          
        

          <Route path="*" element={<Navigate to="/home" replace/>}/>
      </Route>
      
      <Route path="*" element={<Navigate to="/home" replace/>}/>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
//<Route path="Introduction" element={<IntroductionPage/>}/>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
